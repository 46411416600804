#contact-form-container {
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
  // text-align: center;
  //TODO: Possible style
  width: 100%;
  height: 800px;
  background-image: url(../../images/main-page-header1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  grid-template-columns: 50% 40%;
}

.contact-icons {
  position: relative;
  margin-right: 20px;
}

#contact-phone-number {
  position: relative;
  display: block;
  margin-bottom: 10px;
}

#left-contact-details {
  position: relative;
  display: block;
  color: white;
  background-color: rgb(36, 38, 41, 0.9);
  width: 90%;
  height: auto;
  padding: 30px;
  margin: auto;
  font-size: 24px;
  word-wrap: wrap;
}
#left-contact-container h1 {
  color: white;
  font-size: 38px;
}
#left-contact-details p {
  margin-top: 30px;
  margin-bottom: 30px;
}
#left-contact-details a {
  text-decoration: none;
  color: white;
}
.inputContainer {
  position: relative;
  width: 80%;
  color: #bebed2;
  height: contain;
  background-color: rgb(36, 38, 41, 0.9);
  border-radius: 8px;
  box-shadow: 0 0 40px -10px #000;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  padding: 20px 20px;
  max-width: calc(100vw - 40px);
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
.inputContainer h2 {
  color: white;
}
.inputfield {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: none;
  outline: none;
  resize: none;
  border: 0;
  font-family: "Montserrat", sans-serif;
  transition: all 0.3s;
  border-bottom: 2px solid #bebed2;
  color: rgb(195, 192, 192);
}

.inputfield:focus {
  border-bottom: 2px solid #646464;
}
.inputfield p:before {
  content: attr(type);
  display: block;
  margin: 28px 0 0;
  font-size: 14px;
  color: #5a5a5a;
}
.checkboxform {
  position: relative;
  display: inline-block;
}
.checkboxOptions {
  display: block;
}
#btn {
  display: flex;
  padding: 18px 20px;
  margin: auto;
  width: 160px;
  height: auto;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  background: rgb(238, 238, 238);
  color: black;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.5s;
  border-bottom: 6px solid rgb(246, 207, 10);
}
#btn:hover {
  background: rgb(149, 149, 149);
  color: #fff;
}
#checklist {
  --background: #ffffff;
  --text: #414856;
  --check: #4f29f0;
  --disabled: #c3c8de;
  --width: 100px;
  --height: 140px;
  --border-radius: 10px;
  background: rgba(255, 255, 255);
  width: 15vw;
  height: var(--height);
  border-radius: var(--border-radius);
  position: relative;
  box-shadow: 0 10px 30px rgba(65, 72, 86, 0.05);
  padding: 30px 45px;
  display: grid;
  grid-template-columns: 30px auto;
  align-items: center;
  margin: auto;
  margin-bottom: 20px;
}
#checklist label {
  color: var(--text);
  position: relative;
  cursor: pointer;
  display: grid;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: color 0.3s ease;
}
#checklist label::before,
#checklist label::after {
  content: "";
  position: absolute;
}
#checklist label::before {
  height: 2px;
  width: 8px;
  left: -27px;
  background: var(--check);
  border-radius: 2px;
  transition: background 0.3s ease;
}
#checklist label:after {
  height: 4px;
  width: 4px;
  top: 8px;
  left: -25px;
  border-radius: 50%;
}
#checklist input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  height: 15px;
  width: 15px;
  outline: none;
  border: 0;
  margin: 0 15px 0 0;
  cursor: pointer;
  background: var(--background);
  display: grid;
  align-items: center;
}
#checklist input[type="checkbox"]::before,
#checklist input[type="checkbox"]::after {
  content: "";
  position: absolute;
  height: 2px;
  top: auto;
  background: var(--check);
  border-radius: 2px;
}
#checklist input[type="checkbox"]::before {
  width: 0px;
  right: 60%;
  transform-origin: right bottom;
}
#checklist input[type="checkbox"]::after {
  width: 0px;
  left: 40%;
  transform-origin: left bottom;
}
#checklist input[type="checkbox"]:checked::before {
  -webkit-animation: check-01 0.4s ease forwards;
  animation: check-01 0.4s ease forwards;
}
#checklist input[type="checkbox"]:checked::after {
  -webkit-animation: check-02 0.4s ease forwards;
  animation: check-02 0.4s ease forwards;
}
#checklist input[type="checkbox"]:checked + label {
  color: var(--disabled);
  -webkit-animation: move 0.3s ease 0.1s forwards;
  animation: move 0.3s ease 0.1s forwards;
}
#checklist input[type="checkbox"]:checked + label::before {
  background: var(--disabled);
  -webkit-animation: slice 0.4s ease forwards;
  animation: slice 0.4s ease forwards;
}
#checklist input[type="checkbox"]:checked + label::after {
  -webkit-animation: firework 0.5s ease forwards 0.1s;
  animation: firework 0.5s ease forwards 0.1s;
}

@-webkit-keyframes move {
  50% {
    padding-left: 8px;
    padding-right: 0px;
  }
  100% {
    padding-right: 4px;
  }
}

@keyframes move {
  50% {
    padding-left: 8px;
    padding-right: 0px;
  }
  100% {
    padding-right: 4px;
  }
}
@-webkit-keyframes slice {
  60% {
    width: 100%;
    left: 4px;
  }
  100% {
    width: 100%;
    left: -2px;
    padding-left: 0;
  }
}
@keyframes slice {
  60% {
    width: 100%;
    left: 4px;
  }
  100% {
    width: 100%;
    left: -2px;
    padding-left: 0;
  }
}
@-webkit-keyframes check-01 {
  0% {
    width: 4px;
    top: auto;
    transform: rotate(0);
  }
  50% {
    width: 0px;
    top: auto;
    transform: rotate(0);
  }
  51% {
    width: 0px;
    top: 8px;
    transform: rotate(45deg);
  }
  100% {
    width: 5px;
    top: 8px;
    transform: rotate(45deg);
  }
}
@keyframes check-01 {
  0% {
    width: 4px;
    top: auto;
    transform: rotate(0);
  }
  50% {
    width: 0px;
    top: auto;
    transform: rotate(0);
  }
  51% {
    width: 0px;
    top: 8px;
    transform: rotate(45deg);
  }
  100% {
    width: 5px;
    top: 8px;
    transform: rotate(45deg);
  }
}
@-webkit-keyframes check-02 {
  0% {
    width: 4px;
    top: auto;
    transform: rotate(0);
  }
  50% {
    width: 0px;
    top: auto;
    transform: rotate(0);
  }
  51% {
    width: 0px;
    top: 8px;
    transform: rotate(-45deg);
  }
  100% {
    width: 10px;
    top: 8px;
    transform: rotate(-45deg);
  }
}
@keyframes check-02 {
  0% {
    width: 4px;
    top: auto;
    transform: rotate(0);
  }
  50% {
    width: 0px;
    top: auto;
    transform: rotate(0);
  }
  51% {
    width: 0px;
    top: 8px;
    transform: rotate(-45deg);
  }
  100% {
    width: 10px;
    top: 8px;
    transform: rotate(-45deg);
  }
}
@-webkit-keyframes firework {
  0% {
    opacity: 1;
    box-shadow: 0 0 0 -2px #4f29f0, 0 0 0 -2px #4f29f0, 0 0 0 -2px #4f29f0,
      0 0 0 -2px #4f29f0, 0 0 0 -2px #4f29f0, 0 0 0 -2px #4f29f0;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    box-shadow: 0 -15px 0 0px #4f29f0, 14px -8px 0 0px #4f29f0,
      14px 8px 0 0px #4f29f0, 0 15px 0 0px #4f29f0, -14px 8px 0 0px #4f29f0,
      -14px -8px 0 0px #4f29f0;
  }
}
@keyframes firework {
  0% {
    opacity: 1;
    box-shadow: 0 0 0 -2px #4f29f0, 0 0 0 -2px #4f29f0, 0 0 0 -2px #4f29f0,
      0 0 0 -2px #4f29f0, 0 0 0 -2px #4f29f0, 0 0 0 -2px #4f29f0;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    box-shadow: 0 -15px 0 0px #4f29f0, 14px -8px 0 0px #4f29f0,
      14px 8px 0 0px #4f29f0, 0 15px 0 0px #4f29f0, -14px 8px 0 0px #4f29f0,
      -14px -8px 0 0px #4f29f0;
  }
}

body .socials {
  position: fixed;
  display: block;
  left: 20px;
  bottom: 20px;
}
body .socials > a {
  display: block;
  width: 30px;
  opacity: 0.2;
  transform: scale(var(--scale, 0.8));
  transition: transform 0.3s cubic-bezier(0.38, -0.12, 0.24, 1.91);
}
body .socials > a:hover {
  --scale: 1;
}

@media screen and (max-width: 840px) {
  #left-contact-details {
    font-size: 20px;
  }
}
@media screen and (max-width: 680px) {
  #contact-form-container {
    bottom: 10px;
    position: relative;
    display: block;
    height: auto;
  }
  #left-contact-details {
    position: relative;
    padding: 10px;
    align-items: center;
    text-align: center;
    top: 30px;
    margin-bottom: 15px;
  }
  #checklist {
    width: 80%;
  }
}
@media screen and (max-width: 425px) {
  .inputContainer {
    width: 100%;
    background-color: rgb(36, 38, 41, 0.9);
    margin-top: 50px;
  }
  .socialMediaBtns .icons {
    width: 25px;
    height: 25px;
  }
}
