#navbar {
    position: relative;
    width: 100%;
    background-image: url(../../images/wood-background.png);
    background-repeat: no-repeat;
    background-size: cover;
    // background-color: green;
}
#navbar-items {
    position: relative;
    top: 50px;
}
#logo {
    position: relative;
    display: flex;
    width: 15%;
    left: 20px;
    top: 30px;
}

#navbar-items a li {
    cursor: pointer;
}
.navbar-containers {
    position: relative;
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 0;
    top: 0;
    left: -30px;
    border: 2px;
    font-size: 16px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    padding-left: 40px;
    padding-right: 40px;
    background-color: rgba(44, 43, 43, 0.8);
    color: white;
}
#navbar-contact-container {
    position: relative;
    display: block;
    //TODO: this is whats causing the weird white space
    // float: right;
    left: 80vw;
    bottom: 15vh;
    margin-right: 10px;
    font-size: 20px;
    width: 20%;
    text-align: center;
}
#left-contact {
    position: relative;
    float: left;
    padding-left: 20px;
}

#contact-btn {
    color: white;
    text-decoration: none;
}
.phone-number {
    text-decoration: none;
    color: black;
}
#phone-icon {
    position: relative;
    top: 30px;
    right: 40px;
    margin-left: 10px;
}

@media screen and (max-width: 890px) {
    #logo {
        width: 30%;
    }
    .navbar-containers {
        font-size: 14px;
        position: relative;
        display: inline-block;
        left: 0px;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 7px;
        margin-right: 7px;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: auto;
    }
    #navbar-contact-container {
        width: auto;
        height: 60px;
        font-size: 16px;
        margin-right: 0px;
        bottom: 200px;
        padding: 5px;
        left: 19vw;
    }
    #navbar-items {
        position: relative;
        top: 85px;
    }
}
@media screen and (max-width:550px){
    #navbar-items {
        position: relative;
        top: 80px;
    }
    #left-contact {
        float: none;
        padding-left: 0px;
        display: block;
        top: 50px;
    }
    #phone-icon {
        display: block;
        top: -20px;
        left: 70px;
        border-right: 2px solid black;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
