#header-img {
  position: relative;
  display: flex;
  width: fit-content;
  margin: 0 30px 0 auto;
  width: 50%;
}
#header-banner {
  position: relative;
  width: 100%;
  background-size: contain;
}
.main-info-container {
  position: relative;
  display: flex;
  width: 100%;
  margin: auto;
  background-color: grey;
  padding-top: 20px;
  padding-bottom: 20px;
}
.info-containers {
  position: relative;
  display: flex;
  width: auto;
  background-image: url(../../images/wood-background.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px;
  margin: auto;
  justify-content: center;
}
.info-cards {
  position: relative;
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
  background-color: rgb(216, 201, 156, 0.8);
  padding: 20px;
  width: 90%;
}
.info-cards p {
  font-size: 24px;
  padding: 10px;
  text-align: left;
}
.card-header {
  position: relative;
  border-right: 4px solid black;
  padding-top: 50px;
  padding-bottom: 50px;
  align-items: center;
  text-align: center;
}
#lumber-info-container {
  position: relative;
  display: flex;
  padding: 30px;
  background-image: url(../../images/lumber-card-img.jpg);
  background-size:cover;
  background-repeat: no-repeat;
  color: white;
  height: auto;
}
#lumber-info-card {
  position: relative;
  display: grid;
  grid-template-columns: 80% 20%;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 60%;
  
  text-align: right;
  margin-bottom: 50px;
}
#lumber-info-card p {
  font-size: 24px;
  padding: 30px;
  background-color: rgba(128, 128, 128, 0.8);
  margin-right: 10px;
  word-spacing: 8px;
  margin-bottom: 20px;
}
#lumber-info-card h1 {
  font-size: 38px;
  padding-top: 20px;
  color: rgb(0, 0, 0);
  border-left: 3px solid rgb(255, 255, 255);
  color: white;
  padding-left: 10px;
}
#lumber-info-header-mobile {
  display: none;
}

@media screen and (max-width: 500px) {
  #header-img {
    width:100%;
    margin: 0;
    padding: 0;
    align-items: center;
    bottom: 0;
  }
  .info-cards {
    display: block;
  }
  .info-cards p {
    word-spacing: 5px;
  }
  .card-header {
    border-right: none;
    border-bottom: 4px solid black;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  #lumber-info-card {
    display: block;
    height: auto;
    width: auto;
  }
  #lumber-info-card h1 {
    border-left: none;
    border-bottom: 3px solid rgb(255, 255, 255);
  }
  #lumber-info-card p {
    font-size: 18px;
    padding: 15px;
    margin-right: 0px;
    word-spacing: 5px;
    text-align:center;
  }
  #lumber-info-header-mobile {
    display: flex;
    font-size: 16px;
    text-align:center;
    background-color:rgba(128, 128, 128, 0.8);
  
  }
  #lumber-info-header {
    display: none;
    margin-left: 10px;
  }
}

@media screen and (min-width: 890px) {
  #header-banner {
    bottom: 80px;
  }
}
@media screen and (max-width: 885px) {
  #header-banner {
    bottom: 20px;
  }
}