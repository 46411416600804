#header-description {
  position: absolute;
  display: flex;
  z-index: 3;
  margin: auto;
  justify-content: center;
  align-items: center;
  left: 15%;
  width: 70%;
  padding: 20px;
  top: 50%;
  color: white;
  text-align: center;
  background-color: rgba(88, 88, 88, 0.8);
}
#product-header-container {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  gap: none;
}
#lumber-description-container {
  position: relative;
  display: flex;
  margin: auto;
  background-image: url(../../images/lumber-card-img.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  width: auto;
  height: auto;
  justify-content: center;
}
#lumber-description-card {
  position: relative;
  display: block;
  padding: 30px;
  color: white;
}
#lumber-info-description {
  position: relative;
  width: 60%;
  font-size: 22px;
  background-color: rgba(88, 88, 88, 0.8);
  padding: 20px;
  word-spacing: 8px;
}

//Credit to Aaron Iker for button styles below
.continue-application {
  --color: #fff;
  --background: #404660;
  --background-hover: #3a4059;
  --background-left: #2b3044;
  --folder: #f3e9cb;
  --folder-inner: #beb393;
  --paper: #ffffff;
  --paper-lines: #bbc1e1;
  --paper-behind: #e1e6f9;
  --pencil-cap: #fff;
  --pencil-top: #275efe;
  --pencil-middle: #fff;
  --pencil-bottom: #5c86ff;
  --shadow: rgba(13, 15, 25, 0.2);
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  padding: 17px 29px 17px 69px;
  transition: background 0.3s;
  color: var(--color);
  background: var(--bg, var(--background));
  & > div {
    top: 0;
    left: 0;
    bottom: 0;
    width: 53px;
    position: absolute;
    overflow: hidden;
    border-radius: 5px 0 0 5px;
    background: var(--background-left);
    .folder {
      width: 23px;
      height: 27px;
      position: absolute;
      left: 15px;
      top: 13px;
      .top {
        left: 0;
        top: 0;
        z-index: 2;
        position: absolute;
        transform: translateX(var(--fx, 0));
        transition: transform 0.4s ease var(--fd, 0.3s);
        svg {
          width: 24px;
          height: 27px;
          display: block;
          fill: var(--folder);
          transform-origin: 0 50%;
          transition: transform 0.3s ease var(--fds, 0.45s);
          transform: perspective(120px) rotateY(var(--fr, 0deg));
        }
      }
      &:before,
      &:after,
      .paper {
        content: "";
        position: absolute;
        left: var(--l, 0);
        top: var(--t, 0);
        width: var(--w, 100%);
        height: var(--h, 100%);
        border-radius: 1px;
        background: var(--b, var(--folder-inner));
      }
      &:before {
        box-shadow: 0 1.5px 3px var(--shadow), 0 2.5px 5px var(--shadow),
          0 3.5px 7px var(--shadow);
        transform: translateX(var(--fx, 0));
        transition: transform 0.4s ease var(--fd, 0.3s);
      }
      &:after,
      .paper {
        --l: 1px;
        --t: 1px;
        --w: 21px;
        --h: 25px;
        --b: var(--paper-behind);
      }
      &:after {
        transform: translate(var(--pbx, 0), var(--pby, 0));
        transition: transform 0.4s ease var(--pbd, 0s);
      }
      .paper {
        z-index: 1;
        --b: var(--paper);
        &:before,
        &:after {
          content: "";
          width: var(--wp, 14px);
          height: 2px;
          border-radius: 1px;
          transform: scaleY(0.5);
          left: 3px;
          top: var(--tp, 3px);
          position: absolute;
          background: var(--paper-lines);
          box-shadow: 0 12px 0 0 var(--paper-lines),
            0 24px 0 0 var(--paper-lines);
        }
        &:after {
          --tp: 6px;
          --wp: 10px;
        }
      }
    }
    .pencil {
      height: 2px;
      width: 3px;
      border-radius: 1px 1px 0 0;
      top: 8px;
      left: 105%;
      position: absolute;
      z-index: 3;
      transform-origin: 50% 19px;
      background: var(--pencil-cap);
      transform: translateX(var(--pex, 0)) rotate(35deg);
      transition: transform 0.4s ease var(--pbd, 0s);
      &:before,
      &:after {
        content: "";
        position: absolute;
        display: block;
        background: var(
          --b,
          linear-gradient(
            var(--pencil-top) 55%,
            var(--pencil-middle) 55.1%,
            var(--pencil-middle) 60%,
            var(--pencil-bottom) 60.1%
          )
        );
        width: var(--w, 5px);
        height: var(--h, 20px);
        border-radius: var(--br, 2px 2px 0 0);
        top: var(--t, 2px);
        left: var(--l, -1px);
      }
      &:before {
        clip-path: polygon(0 5%, 5px 5%, 5px 17px, 50% 20px, 0 17px);
      }
      &:after {
        --b: none;
        --w: 3px;
        --h: 6px;
        --br: 0 2px 1px 0;
        --t: 3px;
        --l: 3px;
        border-top: 1px solid var(--pencil-top);
        border-right: 1px solid var(--pencil-top);
      }
    }
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 2px;
    border-radius: 1px;
    background: var(--color);
    transform-origin: 9px 1px;
    transform: translateX(var(--cx, 0)) scale(0.5) rotate(var(--r, -45deg));
    top: 26px;
    right: 16px;
    transition: transform 0.3s;
  }
  &:after {
    --r: 45deg;
  }
  &:hover {
    --cx: 2px;
    --bg: var(--background-hover);
    --fx: -40px;
    --fr: -60deg;
    --fd: 0.15s;
    --fds: 0s;
    --pbx: 3px;
    --pby: -3px;
    --pbd: 0.15s;
    --pex: -24px;
  }
}
#main-product-container {
  position: relative;
  display: grid;
  height: contain;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  background-image: url(../../images/wood-background.png);
  background-size: contain;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 4em;
  margin: 20px;
}
#main-product-container img{
  position: relative;
  width: 50%;
  margin: auto;
}

@media screen and (max-width: 450px) {
  #lumber-description-container {
    text-align: center;
  }
  #lumber-info-description {
    width: auto;
    height: auto;
    word-spacing: 5px;
  }
}
