@font-face {
  font-family: mainFont;
  src: url(UniversLightCondensed.ttf);
}

* {
  font-family: mainFont;
}

html,body {
  margin:0;
  padding:0;
  overflow-x: hidden;
}
body {
  // background-image: url(./images/wood-background.png);
  background-size: cover;
  width:auto;
}
#under-construction {
  position: relative;
  display: flex;
  top: 200px;
  justify-content: center;
  margin: auto;
  width: 60%;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

// button,
// button:before {
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// button {
//   position: relative;
//   margin: auto;
//   top: 300px;
//   width: 250px;
//   height: 50px;
//   cursor: pointer;
//   font-family: "Play", sans-serif;
//   font-size: 20px;
//   color: white;
//   border: none;
//   border-radius: 25px;
//   background-image: url("https://assets.codepen.io/3305358/hazard-stripes-wallpaper.jpg");
//   background-size: 150%;
//   background-position: left;
//   transition: 1s;
// }

// button:before {
//   width: 250px;
//   height: 50px;
//   content: "Check Out CBS";
//   background: rgba(0, 0, 0, 0.6);
//   border-radius: 25px;
//   position: absolute;
//   transition: 500ms;
// }

// button:hover:before {
//   background: rgba(0, 0, 0, 0);
//   text-shadow: 1px 1px 1px black;
// }

// button:hover {
//   background-position: center;
//   box-shadow: 0px 0px 10px rgba(232, 218, 26, 0.5);
// }

// button:focus {
//   outline: none;
// }

@media screen and (max-width: 450px) {
  #under-construction {
    width: 100%;
  }
  // button {
  //   width: 200px;
  //   height: 40px;
  // }
  // button::before {
  //   width: 200px;
  //   height: 40px;
  // }
}