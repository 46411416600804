.footer {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  background-color: #303535;
  color: white;
  text-align: center;
  align-items: center;
  bottom: 0;
}
#footerLogo {
  position: relative;
  display: flex;
  width: 40%;
  margin: auto;
  justify-content: center;
}
.contactInfo {
  position: relative;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  align-items: center;
  width: 100%;
}
#logoContainer {
    margin: 0;
}
.footerHeaders {
  margin-top: 10px;
}
.wrapper {
  display: inline-flex;
  list-style: none;
}

.wrapper .icon {
  position: relative;
  background: rgb(71, 70, 70);
  border-radius: 50%;
  padding: 15px;
  margin: 10px;
  right: 15px;
  width: 25px;
  height: 25px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #ffffff;
  color: #000000;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  height: 8px;
  width: 8px;
  background: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}
.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background: #1877f2;
  color: #ffffff;
}
.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
  background: #e4405f;
  color: #ffffff;
}
.wrapper .nova:hover,
.wrapper .nova:hover .tooltip,
.wrapper .nova:hover .tooltip::before {
  background: #34a034;
  color: #ffffff;
}
.wrapper .cbs-lumber:hover,
.wrapper .cbs-lumber:hover .tooltip,
.wrapper .cbs-lumber:hover .tooltip::before {
  background: #049ce3;
  color: #ffffff;
}

.wrapper .homefacebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background: #1877f2;
  color: #ffffff;
}
.wrapper .homeinstagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
  background: #e4405f;
  color: #ffffff;
}
.wrapper .homenova:hover,
.wrapper .nova:hover .tooltip,
.wrapper .nova:hover .tooltip::before {
  background: #34a034;
  color: #ffffff;
}
#creator {
  width: auto;
  position: relative;
  top: 20px;
  padding: 10px;
  text-align: center;
  background-color: rgb(207, 206, 206);
}

@media screen and (max-width: 800px) {
  .wrapper .icon {
    width: 20px;
    height: 20px;
    font-size: 18px;
  }
  .contactInfo {
    display: block;
    font-size:14px;
  }
  #footerLogo {
    display: none;
  }
  .footerHeaders {
    font-size:18px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}